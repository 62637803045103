<template>
  <portal to="portal_popup">
    <modal-window
      ref="modal_window"
      :modal_open="true"
      title="Edit Company Details"
      @close="$emit('close')"
    >
      <div class="grid xs:grid-cols-2 gap-5">
        <div>
          <div class="font-medium">Company</div>
          <input
            v-model="customer.company.name"
            class="form-control bg-gray-200 text-gray-500"
            readonly
          />
        </div>
        <div>
          <div class="font-medium">Contact Number</div>
          <input
            v-if="loggedInUser.isAdmin"
            v-model="customer.company.phone"
            class="form-control"
          />
          <input
            v-if="!loggedInUser.isAdmin"
            v-model="customer.company.phone"
            class="form-control bg-gray-200 text-gray-500"
            readonly
          />
        </div>
        <div>
          <div class="font-medium">Sales Contact</div>
          <select
            v-model="customer.customer.salesContactId"
            class="form-control bg-white"
            :disabled="true"
          >
            <option :value="0">None</option>
            <option
              v-for="staffContact in staffContacts"
              :key="staffContact.id"
              :value="staffContact.id"
            >
              {{ staffContact.firstName }} {{ staffContact.lastName }} - {{ staffContact.email }}
            </option>
          </select>
        </div>
        <div>
          <div class="font-medium">Email</div>
          <input
            v-if="loggedInUser.isAdmin"
            v-model="customer.company.email"
            class="form-control"
          />
          <input
            v-if="!loggedInUser.isAdmin"
            v-model="customer.company.email"
            class="form-control bg-gray-200 text-gray-500"
            readonly
          />
        </div>
        <div>
          <div class="font-medium">Website</div>
          <input v-if="loggedInUser.isAdmin" v-model="customer.company.web" class="form-control" />
          <input
            v-if="!loggedInUser.isAdmin"
            v-model="customer.company.web"
            class="form-control bg-gray-200 text-gray-500"
            readonly
          />
        </div>
        <div>
          <div class="font-medium">Company Registration Number</div>
          <input v-model="customer.eCommerceIntegration.companyRegNumber" class="form-control" />
        </div>
        <div>
          <div class="font-medium">VAT Registration Number</div>
          <input
            v-model="customer.eCommerceIntegration.vatRegistrationNumber"
            class="form-control"
          />
        </div>
      </div>
      <div v-if="loggedInUser.isAdmin" class="flex flex-wrap gap-5 mt-5">
        <div class="flex-1">
          <div class="w-full flex">
            <vue-dropzone
              id="dropzone"
              ref="fileUploadDropzone"
              class="stealth-vue-dropzone"
              :options="dropzoneOptions"
              :use-custom-slot="true"
              @vdropzone-file-added="fileAddedToDropZone"
              @vdropzone-removed-file="new_user_image = null"
            >
              <div class="font-medium">Logo</div>
              <div v-if="!company_logo_missing">
                <div class="flex flex-col justify-around">
                  <div>
                    <img
                      :id="'editing_fabricator_details_logo'"
                      :src="logo_src"
                      style="max-height: 100px"
                      @error="company_logo_missing = true"
                    />
                  </div>
                </div>
              </div>
              <div class="text-green-600 h-10 flex flex-col justify-around cursor-pointer">
                Upload a new logo
              </div>
            </vue-dropzone>
          </div>
        </div>
        <div class="flex-1">
          <div class="font-medium">
            Use 3rd Party Glass
          </div>
          <div class="flex items-center cursor-pointer" @click="useCustomerOnlyGlass = !useCustomerOnlyGlass">
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
              <div class="switch-toggle" :class="{'_active': useCustomerOnlyGlass }"></div>
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
              <span v-text="useCustomerOnlyGlass ? 'On' : 'Off'"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveCompanyDetails()">
          Save Changes
        </button>
      </div>
    </modal-window>
  </portal>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import imageHelper from '@/mixins/imageHelper';
import { mapActions, mapState, mapMutations } from 'vuex';
import validate from 'validate.js';

export default {
  components: {
    'vue-dropzone': vue2Dropzone,
  },
  mixins: [imageHelper],
  props: ['customer', 'staffContacts', 'loggedInUser', 'logo_src'],
  data() {
    return {
      new_user_image: undefined,
      company_logo_missing: false,
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        addRemoveLinks: true,
      },
      options: {
        limit: 20,
        offset: 0,
      },
      useCustomerOnlyGlass: !!this.customer.customer.useCustomerOnlyGlass,
    };
  },
  computed: {
    hasDebugToggle() {
      return typeof this.customer?.customer?.debug !== 'undefined';
    },
  },
  methods: {
    ...mapState('auth', {
      isBranchManagerAvailable: 'isBranchManagerAvailable',
    }),
    ...mapActions({
      customerListBranches: 'user/customerListBranches',
      customerUpdate: 'user/customerUpdateRaw',
    }),
    ...mapMutations('user', {
      setCustomer: 'setCustomer',
    }),
    async fileAddedToDropZone(uploadedFile) {
      this.errors = [];
      if (!uploadedFile) {
        return;
      }

      const extension = uploadedFile.name.replace(/^.*\./, '');
      const allowedExtensions = ['jpg', 'jpeg', 'png'];

      if (!allowedExtensions.includes(extension.toLowerCase())) {
        this.$refs.fileUploadDropzone.dropzone.removeAllFiles();
        this.alertBox().fire({
          title: 'Invalid File Type',
          icon: 'error',
          text: 'File type not allowed, please ensure the file is one of the following formats: jpg, png',
        });
        return;
      }
      this.new_user_image = await this.imageToBase64(uploadedFile);
    },
    toggleCustomerLicenseStatusType() {
      if (
        this.customer.customer.generalLicenseStatus === window.enum.generalLicenseStatus.ENABLED
      ) {
        this.customer.customer.generalLicenseStatus = window.enum.generalLicenseStatus.DISABLED;
      } else {
        this.customer.customer.generalLicenseStatus = window.enum.generalLicenseStatus.ENABLED;
      }
    },
    async saveCompanyDetails() {
      this.$emit('isLoading', true);

      const validationErrors = validate(
        {
          name: this.customer.company.name,
          email: this.customer.company.email,
          phone: this.customer.company.phone,
          website: this.customer.company.web,
        },
        {
          name: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          phone: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          email: {
            presence: { allowEmpty: false },
            length: {
              maximum: 255,
            },
            email: true,
          },
        },
      );
      if (validationErrors) {
        this.$emit('isLoading', false);
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      if (this.useCustomerOnlyGlass !== !!this.customer.customer.useCustomerOnlyGlass) {
        await window.touch.UpdateCustomerOnlyGlass(undefined, this.useCustomerOnlyGlass);
        this.customer.customer.useCustomerOnlyGlass = this.useCustomerOnlyGlass;
      }

      await this.customerUpdate({
        Company: {
          email: this.customer.company.email,
          phone: this.customer.company.phone,
          web: this.customer.company.web,
          name: undefined,
        },
        eCommerceIntegration: {
          companyRegNumber: this.customer.eCommerceIntegration.companyRegNumber,
          vatRegistrationNumber: this.customer.eCommerceIntegration.vatRegistrationNumber,
        },
        Styling: {
          logo: this.new_user_image,
        },
        Customer: undefined,
        CustomerId: undefined,
      });

      this.setCustomer(this.customer);

      this.$emit('isLoading', false);
      this.$emit('close');
    },
  },
};
</script>
